import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { Edit } from '@material-ui/icons';
import { Typography, IconButton } from '@material-ui/core';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import CheckIcon from '@material-ui/icons/Check';
import MaterialTable from 'material-table';
import * as _ from 'lodash';
import { useLazyQuery } from '@apollo/react-hooks';
import { getToken, navigate, checkJWTExpired, parseGraphQLErrorMessage } from '../utils/utils';
import { ErrorDialog, VASPTitleTypography } from '../components/common';
import Layout from '../components/layout';
import Navigator from '../components/navigator';
import AuthenticatedPage from '../components/authenticated-page';
import { EditBundleServiceDialog } from '../components/bundle-service';
import { GET_VASP_BUNDLE_SERVICE } from '../crud';
import { BundleServiceStatus } from '../constants';

function BundleServiceVASP({ location }) {
  const token = getToken();

  const targetVASPCode = _.get(location, ['state', 'parameter', 'vasp_code'], undefined);
  const targetVASPName = _.get(location, ['state', 'parameter', 'vasp_name'], undefined);

  if (!targetVASPCode || !targetVASPName) {
    navigate('/');
    return '';
  }

  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [error, setError] = useState('');

  const [vaspBundleService, setVASPBundleService] = useState([]);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedService, setSelectedService] = useState({
    vasp_code: targetVASPCode,
    vasp_name: targetVASPName,
    service_provider: null,
    service_type: null,
    service_desc: null,
    credential: {},
  });

  const [getVASPBundleService] = useLazyQuery(GET_VASP_BUNDLE_SERVICE, {
    variables: {
      vasp_code: targetVASPCode,
    },
    context: {
      headers: {
        authorization: token,
      },
    },
    onCompleted: (data) => {
      if (!data) {
        setErrorDialogOpen(true);
        setError(parseGraphQLErrorMessage('data is undefined'));
        return;
      }
      if (_.has(data, 'getVaspBundleService')) {
        let clone = _.cloneDeep(data.getVaspBundleService);
        clone = clone.map((e) => ({
          ...e,
          ...e.credential, // for render component easier
          started_at: e.started_at && moment(e.started_at).format('YYYY-MM-DD HH:mm'),
          ended_at: e.ended_at && moment(e.ended_at).format('YYYY-MM-DD HH:mm'),
        }));
        clone.sort((a, b) => {
          const serviceDescA = a.service_desc.toUpperCase();
          const serviceDescB = b.service_desc.toUpperCase();
          if (serviceDescA < serviceDescB) {
            return -1;
          }
          if (serviceDescA > serviceDescB) {
            return 1;
          }

          const servicepPoviderA = a.service_provider.toUpperCase();
          const servicepPoviderB = b.service_provider.toUpperCase();
          if (servicepPoviderA < servicepPoviderB) {
            return -1;
          }
          if (servicepPoviderA > servicepPoviderB) {
            return 1;
          }

          return 0;
        });
        setVASPBundleService(clone);
      } else {
        setVASPBundleService([]);
      }
    },
    onError: (e) => {
      setErrorDialogOpen(true);
      setError(parseGraphQLErrorMessage(e));
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (token && !checkJWTExpired(token)) {
      getVASPBundleService();
    }
  }, []);

  const handleSubmit = () => {
    setEditDialogOpen(false);
    if (token && !checkJWTExpired(token)) {
      getVASPBundleService();
    }
  };

  const handleError = (e) => {
    setErrorDialogOpen(true);
    setError(e);
  };

  const now = moment();
  now.set({ second: 0, millisecond: 0 });

  return (
    <Layout>
      <AuthenticatedPage>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          </Helmet>
        </div>
        <Navigator
          breadcrumbTexts={['Home', 'Bundle Service', 'VASP']}
          breadcrumbLinks={['/', '/bundle-service-vasp-list', '/bundle-service-vasp']}
        />
        <VASPTitleTypography vaspCode={targetVASPCode} vaspName={targetVASPName} />
        <MaterialTable
          title=""
          columns={[
            {
              title: 'Active',
              field: 'is_active',
              render: (data) => {
                const rowID = data.tableData.id;
                if (data.status === BundleServiceStatus.Activated) {
                  return (
                    <CheckIcon id={`service_active_${rowID}`} name="check" color="secondary" />
                  );
                }
                if (data.status === BundleServiceStatus.Upcoming) {
                  return (
                    <AlarmOnIcon id={`service_active_${rowID}`} name="alarm" color="secondary" />
                  );
                }
                return <div id={`service_active_${rowID}`} name="empty" />;
              },
              width: 20,
            },
            { title: 'Bundle Service Type', field: 'service_desc' },
            { title: 'Service Provider', field: 'service_provider' },
            {
              title: 'Starting Time',
              field: 'started_at',
              render: (rowData) => (
                <Typography variant="subtitle2" id={`starting_time_${rowData.tableData.id}`}>
                  {rowData.started_at || ''}
                </Typography>
              ),
            },
            {
              title: 'Ending Time',
              field: 'ended_at',
              render: (rowData) => (
                <Typography variant="subtitle2" id={`ending_time_${rowData.tableData.id}`}>
                  {rowData.ended_at}
                </Typography>
              ),
            },
          ]}
          data={vaspBundleService}
          actions={[
            {
              icon: Edit,
              onClick: (__, rowData) => {
                setSelectedService({
                  ...rowData,
                  started_at: rowData.started_at || now.format('YYYY-MM-DD HH:mm'),
                  ended_at: rowData.ended_at,
                });
                setEditDialogOpen(true);
              },
            },
          ]}
          options={{
            headerStyle: { padding: '1em' },
          }}
          // add id for action icon
          components={{
            Action: (actionProps) => {
              const { action, data } = actionProps;
              return (
                <IconButton
                  size="medium"
                  onClick={(event) => action.onClick(event, data)}
                  id={`ActionIcon_${data.tableData.id}`}
                >
                  <action.icon style={{ color: 'black' }} />
                </IconButton>
              );
            },
          }}
        />
        <ErrorDialog
          id="error_dialog"
          open={{ get: errorDialogOpen, set: setErrorDialogOpen }}
          message={error}
        />
        <EditBundleServiceDialog
          id="edit_bundle_service_dialog"
          token={token}
          open={{ get: editDialogOpen, set: setEditDialogOpen }}
          data={selectedService}
          onSubmit={handleSubmit}
          onError={handleError}
        />
      </AuthenticatedPage>
    </Layout>
  );
}

BundleServiceVASP.defaultProps = {
  location: undefined,
};

BundleServiceVASP.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      parameter: PropTypes.shape({
        vasp_code: PropTypes.string,
        vasp_name: PropTypes.string,
      }),
    }),
  }),
};

export default BundleServiceVASP;
