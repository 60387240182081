import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import * as _ from 'lodash';
import Case from 'case';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  HookFormTextField,
  NormalButton,
  CustomChildDialog,
  ResizeTextField,
  CustomDateTimePicker,
  LoadingButton,
} from '../common';
import { ErrorMessages, INVALID_ENDING_TIME_ERROR } from '../../constants';
import { SWITCH_BUNDLE_SERVICE } from '../../crud';
import { parseGraphQLErrorMessage, isValidDate } from '../../utils/utils';

const useStyles = makeStyles(() => ({
  marginVertical: {
    marginTop: '1em',
    marginBottom: '1em',
  },
}));

const CREDENTIAL_PREFIX = 'credential_';

export default function EditBundleServiceDialog({ id, token, open, data, onSubmit, onError }) {
  const classes = useStyles();

  // save showPassword attribute in state for each credential textField
  const [attributes, setAttributes] = useState(
    Object.keys(data.credential).reduce((accumulator, currentValue) => {
      accumulator[currentValue] = {
        showPassword: false,
      };
      return accumulator;
    }, {}),
  );

  const [isActive, setActive] = useState(data.is_active);
  const [createLoading, setCreateLoading] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);

  const [minEndedAt, setMinEndedAt] = useState(moment(data.started_at));

  const { handleSubmit, register, errors, setValue, reset, control, getValues, clearErrors } =
    useForm({
      mode: 'onBlur',
      reValidateMode: 'onChange',
      defaultValues: data,
      resolver: undefined,
      context: undefined,
      criteriaMode: 'firstError',
      shouldFocusError: true,
      shouldUnregister: true,
    });

  const [switchBundleServiceMutation] = useMutation(SWITCH_BUNDLE_SERVICE);

  // a key name of submitData is the name of textField
  const onFormSubmit = async (submitData) => {
    // if a key is belong to credential, push it into the credential key of the accumulator
    const formattedSubmitData = Object.keys(submitData).reduce((accumulator, currentValue) => {
      const keys = Object.keys(data.credential);
      if (keys.includes(currentValue)) {
        accumulator.credential = {
          ...accumulator.credential,
          [currentValue]: submitData[currentValue],
        };
      } else {
        accumulator[currentValue] = submitData[currentValue];
      }
      return accumulator;
    }, {});

    // hook-form only contain data which are editable in the form, so it must be combined with prpps.data to be input of mutation
    const input = _.assignWith(
      { ...data, is_active: isActive },
      formattedSubmitData,
      (objValue, srcValue) =>
        !srcValue && srcValue !== false && srcValue !== 0 ? objValue : srcValue,
    );
    // ended_at is nullable and clearable, so the srcValue would be ignore if it is null when using assignWith
    if (input.ended_at !== formattedSubmitData.ended_at) {
      input.ended_at = formattedSubmitData.ended_at;
    }

    if (_.isEqual(data, input) || (data.is_active === false && input.is_active === false)) {
      onSubmit();
      return;
    }

    setCreateLoading(true);
    try {
      await switchBundleServiceMutation({
        variables: {
          input: {
            vasp_code: input.vasp_code,
            service_provider: input.service_provider,
            service_type: input.service_type,
            service_desc: input.service_desc,
            is_active: input.is_active,
            started_at: input.is_active ? moment(input.started_at).toISOString() : undefined,
            ended_at: input.is_active ? moment(input.ended_at).toISOString() : undefined,
            credential: input.is_active ? input.credential : undefined,
          },
        },
        context: {
          headers: {
            authorization: token,
          },
        },
      });
      setCreateLoading(false);
      setCreateSuccess(true);
      onSubmit();
    } catch (error) {
      setCreateLoading(false);
      onError(parseGraphQLErrorMessage(error));
    }
  };

  const handleClickShowPassword = (key) => {
    setAttributes({
      ...attributes,
      [key]: { ...attributes[key], showPassword: !attributes[key].showPassword },
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = () => {
    setActive(!isActive);
    reset();
  };

  useEffect(() => {
    if (open.get) {
      setAttributes(
        Object.keys(data.credential).reduce((accumulator, currentValue) => {
          accumulator[currentValue] = {
            showPassword: false,
          };
          return accumulator;
        }, {}),
      );
      setActive(data.is_active);
      setCreateLoading(false);
      setCreateSuccess(false);
      setMinEndedAt(moment(data.started_at));
      reset(data);
    }
  }, [open.get]);

  const isEndedAtAfterNow5Minute = (endedAt) => {
    const now = moment().second(0).millisecond(0);
    return !moment(endedAt).isBefore(now.add(5, 'm'));
  };

  const isEndedAtAfterStartedAt = (endedAt) => {
    const startedAt = moment(getValues('started_at'));
    return !moment(endedAt).isBefore(startedAt.add(1, 'm'));
  };

  return (
    <CustomChildDialog
      id={id}
      onClose={() => {
        reset();
        open.set(false);
      }}
      title="Settings"
      timeout={0}
      open={open}
      disableBackdropClick
      disableEscapeKeyDown={createLoading}
      disableCloseIcon={createLoading}
      showDialogActions={false}
      dividers
      showCloseIcon
      child={
        <form onSubmit={handleSubmit(onFormSubmit)} autoComplete="off">
          <section className={classes.marginVertical}>
            <ResizeTextField
              id="service_desc"
              name="service_desc"
              value={data.service_desc}
              label="Service Type"
              disabled
              style={{ margin: 4, width: 400 }}
            />
          </section>

          <section className={classes.marginVertical}>
            <ResizeTextField
              id="service_provider"
              name="service_provider"
              value={data.service_provider}
              label="Service Provider"
              disabled
              style={{ margin: 4, width: 400 }}
            />
          </section>

          <FormControlLabel
            control={
              <Switch
                checked={isActive}
                onChange={handleChange}
                name="checkedActive"
                id="activeSwitch"
              />
            }
            label="Active"
          />

          {isActive && (
            <>
              {Object.keys(data.credential).map((key, index) => (
                <section key={key} className={classes.marginVertical}>
                  <HookFormTextField
                    key={key}
                    id={`${CREDENTIAL_PREFIX}${index}`}
                    name={key}
                    type={attributes[key] && attributes[key].showPassword ? 'text' : 'password'}
                    style={{ margin: 4, width: 400 }}
                    fullWidth={false}
                    label={Case.capital(key)}
                    forwardedRef={register({
                      required: true,
                      pattern: /^[A-Za-z0-9-_]+$/,
                    })}
                    onChange={(e) => setValue(key, e.target.value)}
                    error={errors[key]}
                    parseError={(e) => {
                      const type = _.get(e, ['type'], undefined);
                      if (!type) {
                        return '';
                      }
                      if (type === 'required') {
                        return ErrorMessages.Required;
                      }
                      return ErrorMessages.InvalidPureTextFormat;
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleClickShowPassword(key)}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {attributes[key] && attributes[key].showPassword ? (
                              <Visibility id={`${CREDENTIAL_PREFIX}${index}_showPassword`} />
                            ) : (
                              <VisibilityOff id={`${CREDENTIAL_PREFIX}${index}_showPassword`} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </section>
              ))}

              <section className={classes.marginVertical}>
                <Controller
                  render={({ onChange, ...props }) => (
                    <CustomDateTimePicker
                      {...props}
                      id="started_at"
                      label="Starting Time"
                      selectedDate={_.get(props, ['value'])}
                      onDateChange={(val) => {
                        setMinEndedAt(moment(val));
                        onChange(val);
                      }}
                      style={{ margin: 4, width: 400 }}
                      error={errors.started_at}
                      parseError={(e) => {
                        const type = _.get(e, ['type'], undefined);
                        if (!type) {
                          return '';
                        }
                        if (type === 'required') {
                          return ErrorMessages.Required;
                        }
                        return ErrorMessages.InvalidDateFormat;
                      }}
                      allowKeyboardControl
                    />
                  )}
                  name="started_at"
                  onChange={(val) => {
                    setValue('started_at', val);
                  }}
                  control={control}
                  rules={{
                    required: true,
                    validate: {
                      isValidDate,
                    },
                  }}
                  defaultValue={data.started_at}
                />
              </section>

              <section className={classes.marginVertical}>
                <Controller
                  render={({ onChange, ...props }) => (
                    <CustomDateTimePicker
                      {...props}
                      id="ended_at"
                      label="Ending Time"
                      selectedDate={_.get(props, ['value'])}
                      onDateChange={(val) => {
                        onChange(val);
                        if (!val) {
                          clearErrors('ended_at');
                        }
                      }}
                      minDate={minEndedAt}
                      style={{ margin: 4, width: 400 }}
                      error={errors.ended_at}
                      parseError={(e) => {
                        const type = _.get(e, ['type'], undefined);
                        if (!type) {
                          return '';
                        }
                        if (type === 'isValidDate') {
                          return ErrorMessages.InvalidDateFormat;
                        }
                        if (type === 'isEndedAtAfterNow5Minute') {
                          return ErrorMessages.InvalidEndingTime(
                            INVALID_ENDING_TIME_ERROR.TOO_SHORT,
                          );
                        }
                        return ErrorMessages.InvalidEndingTime(
                          INVALID_ENDING_TIME_ERROR.LESS_THAN_STARTING_TIME,
                        );
                      }}
                      clearable
                      allowKeyboardControl
                    />
                  )}
                  name="ended_at"
                  onChange={(val) => {
                    setValue('ended_at', val);
                  }}
                  control={control}
                  rules={{
                    validate: {
                      isValidDate,
                      isEndedAtAfterNow5Minute,
                      isEndedAtAfterStartedAt,
                    },
                  }}
                  defaultValue={data.ended_at}
                />
              </section>
            </>
          )}

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              id="save"
              loading={createLoading}
              done={{ get: createSuccess, set: setCreateSuccess }}
              buttonType="submit"
              buttonText="Save"
              buttonStyle={{ width: '6em', marginLeft: '1em' }}
            />
            <NormalButton
              disabled={createLoading}
              id="cancel"
              buttonText="Cancel"
              buttonStyle={{ width: '6em', marginLeft: '1em' }}
              onClick={() => {
                reset();
                open.set(false);
              }}
            />
          </div>
        </form>
      }
    />
  );
}
EditBundleServiceDialog.defaultProps = {
  onError: () => {},
};

EditBundleServiceDialog.propTypes = {
  id: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  open: PropTypes.shape({
    get: PropTypes.bool,
    set: PropTypes.func,
  }).isRequired,
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func,
};
